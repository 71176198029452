import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import scrollToElement from 'scroll-to-element';
import Link from 'components/atoms/text/Link/Link';
import AppContext from 'components/AppContext';

import './PrimaryNav.scss';

const PrimaryNav = (props) => {
  const {
    items,
  } = props;

  const contextState = useContext(AppContext);

  const handleLinkClick = (e, target) => {
    // NODE-SAFE CODE
    // Gatsby uses Node to generate our pages.
    // Node doesn't know what a window is.
    // Be sure to wrap any of your browser interactions
    // in some sort of node-safe if statement like this:
    if (typeof window !== 'undefined') {
      // First, are we on the home page?
      // If so, let's scroll to the desired block,
      // which was passed in as an onClick method on our <Link />.
      // If an event was also passed, we'll preventDefault()
      // if (window.location.pathname === '/') {
      // if (e) e.preventDefault();
      scrollToElement(target, {
        offset: 0, // Offset a fixed header if you please
        duration: 1000,
      });
      // }
    }
  };

  if (items && items.length > 1) {
    return (
      <ul className="o-layout  o-layout--align-right  c-primary-nav">
        { items
          ? items.map((item) => (
            <li
              key={`/${item.text}`}
              className="o-layout__cell  o-layout__cell--fit@from-desk  c-primary-nav__item"
            >
              <Link
                to={item.href}
                className={classNames(
                  'c-primary-nav__item-link',
                  {
                    'is-active': item.isActive,
                  },
                )}
                activeClassName="is-active"
                onClick={(e) => {
                  contextState.set({
                    menuOpen: false,
                  });

                  if (item.anchor) {
                    handleLinkClick(e, item.href);
                  }
                }}
              >
                <span>{item.text}</span>
              </Link>
            </li>
          ))
          : null }
      </ul>
    );
  }

  return null;
};

PrimaryNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

PrimaryNav.defaultProps = {
  items: [],
};

export default PrimaryNav;
