import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/atoms/text/Link/Link';

import Icon from '../Icons/Icon';

import './Button.scss';

const Button = ({
  type,
  onClick,
  href,
  target,
  title,
  reversed,
  disabled,
  tag,
  modifier,
  text,
  icon,
  iconSize,
  iconModifier,
  hideLabel,
}) => {
  let rel = null;
  if (target === '_blank') {
    rel = 'noopener noreferrer';
  }

  let $el = tag;

  if (tag === 'a') {
    $el = Link;
  }

  return (
    React.createElement(
      $el,
      {
        className: classNames('c-button', {
          'c-button--secondary': modifier === 'secondary',
          'c-button--flat': modifier === 'flat',
          'c-button--reversed': reversed,
        }),
        type: type || null,
        onClick,
        to: tag === 'a' ? href : null,
        rel,
        title,
        disabled,
      },
      <span className="c-button__inner">
        {icon ? (
          <span className="c-button__wrap-icon">
            <Icon icon={icon} size={iconSize} modifier={iconModifier} />
          </span>
        ) : null}
        <span
          className={classNames('c-button__label', {
            'u-visually-hidden': hideLabel,
          })}
        >
          {text}
        </span>
      </span>,
    )
  );
};

Button.propTypes = {
  tag: PropTypes.string,
  modifier: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  href: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  title: PropTypes.string,
  reversed: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.string,
  iconModifier: PropTypes.string,
  iconSize: PropTypes.string,
  hideLabel: PropTypes.bool,
};

Button.defaultProps = {
  tag: 'a',
  modifier: '',
  type: null,
  href: null,
  onClick: null,
  target: null,
  title: null,
  reversed: false,
  disabled: false,
  text: 'Button label',
  icon: '',
  iconModifier: '',
  iconSize: '',
  hideLabel: false,
};

export default Button;
