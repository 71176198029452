import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Heading.scss';

const Heading = ({
  text,
  level,
  stylingLevel,
  isLowerCase,
  className,
}) => {
  // if the text is empty do not render this
  if (!text) {
    return null;
  }

  const HeadingTag = `h${level}`;

  return (
    <HeadingTag
      className={classNames('c-heading', {
        'c-heading--h1': stylingLevel === 1,
        'c-heading--h2': stylingLevel === 2,
        'c-heading--h3': stylingLevel === 3,
        'c-heading--h4': stylingLevel === 4,
        'c-heading--h5': stylingLevel === 5,
        'c-heading--h6': stylingLevel === 6,
        'c-heading--lowercase': isLowerCase,
      }, className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

Heading.propTypes = {
  level: PropTypes.number,
  stylingLevel: PropTypes.number,
  text: PropTypes.string,
  isLowerCase: PropTypes.bool,
  className: PropTypes.string,
};

Heading.defaultProps = {
  level: 2,
  stylingLevel: null,
  text: '',
  isLowerCase: false,
  className: '',
};

export default Heading;
