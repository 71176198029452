import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Layer.scss';

const Layer = ({
  id,
  className,
  children,
  size,
  modifier,
}) => (
  <section
    id={id}
    className={classNames(`o-layer  ${className}`, {
      'o-layer--huge': size === 'huge',
      'o-layer--large': size === 'large',
      'o-layer--medium': size === 'medium',
      'o-layer--small': size === 'small',
      'o-layer--tiny': size === 'tiny',
      'o-layer--flat-top': modifier === 'flatTop',
    })}
  >
    {children}
  </section>
);

Layer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
  modifier: PropTypes.string,
};
Layer.defaultProps = {
  id: '',
  className: '',
  children: [],
  size: '',
  modifier: '',
};

export default Layer;
