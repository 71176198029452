import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppContext from 'components/AppContext';

import Retain from 'components/atoms/objects/Retain/Retain';
import Logo from 'components/atoms/Logo/Logo';
import Burger from 'components/atoms/Burger/Burger';
import PrimaryNav from 'components/molecules/PrimaryNav/PrimaryNav';

import './Header.scss';

const Header = (props) => {
  const {
    items,
  } = props;

  const contextState = useContext(AppContext);

  const toggleMenu = () => {
    if (contextState.data.menuOpen) {
      contextState.set({
        menuOpen: false,
      });
    } else {
      contextState.set({
        menuOpen: true,
      });
    }
  };

  return (
    <header
      className={classNames('c-header', {
        'is-open': contextState.data.menuOpen,
      })}
    >
      <Retain size="wall">
        <div className="o-layout  o-layout--gutter  o-layout--align-middle">
          <div className="o-layout__cell  o-layout__cell--fit">
            <div className="c-header__logo-wrap">
              <Logo
                logoLink="/"
              />
            </div>
          </div>
          <div className="o-layout__cell  o-layout__cell--fill">
            <div className="c-header__wrap-contained">
              <Burger
                openWord="Menu"
                closeWord="Sluiten"
                onClick={toggleMenu}
                menuOpen={contextState.data.menuOpen}
              />
            </div>
            <div className="c-header__expanded">
              <PrimaryNav
                items={items}
              />
            </div>
          </div>
        </div>
      </Retain>
    </header>
  );
};

Header.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

Header.defaultProps = {
  items: [],
};

export default Header;
