/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { ContextProviderComponent } from 'components/AppContext';

import FooterData from 'components/organisms/Footer/Footer.data';

import Header from 'components/organisms/Header/Header';
import Footer from 'components/organisms/Footer/Footer';

import 'sass/leading.scss';

const Layout = ({ children }) => {
  const navItems = useStaticQuery(graphql`
    query SiteTitleQuery {
      primaryNavJson {
        items {
          text
          href
          title
          anchor
        }
      }
    }
  `);

  return (
    <>
      <ContextProviderComponent>
        <Header items={navItems.primaryNavJson.items} />
        <main>{children}</main>
        <Footer {...FooterData} />
      </ContextProviderComponent>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
