import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Burger.scss';

const Burger = ({
  openWord,
  closeWord,
  onClick,
  menuOpen,
}) => (
  <button
    className={classNames('c-burger', {
      'is-active': menuOpen,
    })}
    type="button"
    onClick={(e) => onClick(e)}
    aria-label="Navigation"
    aria-expanded={menuOpen}
  >
    <span className="c-burger__bun">
      <span className="c-burger__meat" />
    </span>
    <span className="c-burger__label u-size--micro">
      <span className="u-visually-hidden">Open</span>
      { menuOpen ? closeWord : openWord }
    </span>
  </button>
);

Burger.propTypes = {
  openWord: PropTypes.string,
  closeWord: PropTypes.string,
  onClick: PropTypes.func,
  menuOpen: PropTypes.bool,
};

Burger.defaultProps = {
  openWord: '',
  closeWord: '',
  onClick: null,
  menuOpen: false,
};

export default Burger;
