import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles
import './Icon.scss';

import IconArrowRight from './svgs/icon-arrow-right.inline.svg';
import IconInstagram from './svgs/instagram.inline.svg';
import IconWhatsapp from './svgs/whatsapp.inline.svg';
import IconFacebook from './svgs/facebook.inline.svg';
import IconLinkedin from './svgs/linkedin.inline.svg';
import IconAlert from './svgs/alert.inline.svg';
import IconZoom from './svgs/search.inline.svg';

const library = {
  'icon-arrow-right': IconArrowRight,
  'icon-instagram': IconInstagram,
  'icon-whatsapp': IconWhatsapp,
  'icon-facebook': IconFacebook,
  'icon-linkedin': IconLinkedin,
  'icon-alert': IconAlert,
  'icon-zoom': IconZoom,
};

const Icon = ({ icon, size, className }) => (
  React.createElement(
    library[icon],
    {
      className: classNames(`c-icon ${className}`, {
        'c-icon--alpha': size === 'alpha',
        'c-icon--beta': size === 'beta',
        'c-icon--gamma': size === 'gamma',
        'c-icon--delta': size === 'delta',
        'c-icon--epsilon': size === 'epsilon',
        'c-icon--zeta': size === 'zeta',
        'c-icon--milli': size === 'milli',
      }),
    },
  )
);

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
};

Icon.defaultProps = {
  icon: '',
  className: '',
  size: '',
};

export default Icon;
