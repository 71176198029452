import React from 'react';
import PropTypes from 'prop-types';

const defaultContextValue = {
  data: {
    // set your initial data shape here
    menuOpen: false,
  },
  set: () => {},
};

const AppContext = React.createContext(defaultContextValue);

class ContextProviderComponent extends React.Component {
  constructor() {
    super();

    this.setData = this.setData.bind(this);
    this.state = {
      ...defaultContextValue,
      // We DO use this, except not in this file
      // eslint-disable-next-line react/no-unused-state
      set: this.setData,
    };
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    const {
      children,
    } = this.props;

    return <AppContext.Provider value={this.state}>{children}</AppContext.Provider>;
  }
}

ContextProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext as default, ContextProviderComponent };
