import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = (
  {
    children,
    to,
    activeClassName,
    partiallyActive,
    ...other
  },
) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <AniLink
        cover
        direction="left"
        duration={1}
        bg="#2b2b2b"
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </AniLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
};

Link.defaultProps = {
  children: [],
  to: '',
  activeClassName: '',
  partiallyActive: false,
};

export default Link;
