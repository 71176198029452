import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/atoms/Button/Button';

const ListButtons = (props) => {
  const {
    items,
    modifier,
    className,
    inline,
  } = props;

  return (
    <ul className={classNames('o-list-clean  o-layout  o-layout--gutter-small', {
      'o-list-inline': modifier === 'inline',
    }, className)}
    >
      {items ? items.map((item) => (
        <li
          key={item.button.text}
          className={classNames('o-layout__cell', {
            'o-layout__cell--fit': inline === true,
          })}
        >
          <Button
            {...item.button}
          />
        </li>
      )) : null }
    </ul>
  );
};

ListButtons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  modifier: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
};

ListButtons.defaultProps = {
  items: null,
  modifier: null,
  className: null,
  inline: true,
};

export default ListButtons;
