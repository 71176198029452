import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/atoms/text/Link/Link';

import LogoSVG from './images/aertsbeton-logo.inline.svg';

// Styles
import './Logo.scss';

const Logo = (props) => {
  const {
    logoLink,
  } = props;

  const logoStructure = (
    <LogoSVG />
  );

  if (logoLink) {
    return (
      <Link
        to={logoLink}
        title="Aerts Beton"
        className="c-logo"
      >
        {logoStructure}
      </Link>
    );
  }

  return <div className="c-logo">{logoStructure}</div>;
};

Logo.propTypes = {
  logoLink: PropTypes.string,
};


Logo.defaultProps = {
  logoLink: '',
};

export default Logo;
