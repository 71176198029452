import React from 'react';
import PropTypes from 'prop-types';

import Layer from 'components/atoms/objects/Layer/Layer';
import Retain from 'components/atoms/objects/Retain/Retain';
import Logo from 'components/atoms/Logo/Logo';
import ListButtons from 'components/molecules/ListButtons/ListButtons';
import Heading from 'components/atoms/text/Heading/Heading';

import './Footer.scss';

const Footer = (props) => {
  const {
    heading,
    socialButtons,
  } = props;

  return (
    <section className="c-footer">
      <Layer size="medium">
        <Retain size="wall">
          <div className="o-layout  o-layout--gutter  o-layout--align-middle  o-layout--justify-between">
            <div className="o-layout__cell  o-layout__cell--fit@from-lap">
              <div className="c-footer__logo">
                <Logo
                  logoLink="/"
                />
              </div>
            </div>
            <div className="o-layout__cell  o-layout__cell--fit@from-lap">
              <Heading {...heading} />
              <div className="o-layout  o-layout--gutter">
                <div className="o-layout__cell  u-fraction--6/12@from-lap">
                  <div className="c-footer__info">
                    <ul className="o-list-clean">
                      <li>Spagetstraat 8</li>
                      <li>5708 AL Helmond</li>
                      <li>KVK 62908758</li>
                    </ul>
                  </div>
                </div>
                <div className="o-layout__cell  u-fraction--6/12@from-lap">
                  <div className="c-footer__info">
                    <ul className="o-list-clean">
                      <li><a href="mailto:info@aertsbeton.nl">info@aertsbeton.nl</a></li>
                      <li><a href="tel:06 20 94 40 84">06 20 94 40 84</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="o-layout__cell  o-layout__cell--fit@from-lap">
              <ListButtons
                {...socialButtons}
                className="o-layout--align-center"
              />
            </div>
          </div>
        </Retain>
      </Layer>
    </section>
  );
};

Footer.propTypes = {
  heading: PropTypes.objectOf(PropTypes.any),
  socialButtons: PropTypes.objectOf(PropTypes.any).isRequired,
};

Footer.defaultProps = {
  heading: null,
};

export default Footer;
