const scrollToElement = require('scroll-to-element');

exports.onRouteUpdate = ({ location }) => {
  const { hash } = (location);
  if (hash) {
    scrollToElement(hash, {
      offset: 0,
      duration: 1000,
    });
  }
};
