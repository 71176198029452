import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Retain.scss';

const Retain = ({ size, children, noPadding }) => (
  <div className={classNames('o-retain', {
    'o-retain--desk': size === 'desk',
    'o-retain--wall': size === 'wall',
    'o-retain--small': size === 'small',
    'o-retain--no-padding': noPadding,
  })}
  >
    {children}
  </div>
);

Retain.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  noPadding: PropTypes.bool,
};

Retain.defaultProps = {
  children: [],
  size: null,
  noPadding: false,
};

export default Retain;
