// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bedrijfsvloeren-jsx": () => import("./../src/pages/bedrijfsvloeren.jsx" /* webpackChunkName: "component---src-pages-bedrijfsvloeren-jsx" */),
  "component---src-pages-contact-bedankt-jsx": () => import("./../src/pages/contact-bedankt.jsx" /* webpackChunkName: "component---src-pages-contact-bedankt-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-fotos-jsx": () => import("./../src/pages/fotos.jsx" /* webpackChunkName: "component---src-pages-fotos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kleurenpalet-jsx": () => import("./../src/pages/kleurenpalet.jsx" /* webpackChunkName: "component---src-pages-kleurenpalet-jsx" */),
  "component---src-pages-over-ons-jsx": () => import("./../src/pages/over-ons.jsx" /* webpackChunkName: "component---src-pages-over-ons-jsx" */),
  "component---src-pages-terrasvloeren-jsx": () => import("./../src/pages/terrasvloeren.jsx" /* webpackChunkName: "component---src-pages-terrasvloeren-jsx" */),
  "component---src-pages-woonvloeren-jsx": () => import("./../src/pages/woonvloeren.jsx" /* webpackChunkName: "component---src-pages-woonvloeren-jsx" */)
}

